import React from "react"
import { Link } from "gatsby"
import ModulrPdf from "../assets/Modulr_(MFSL)_-_UK_Safeguarding_Explanation_-_Jun_2021.pdf"

const Footer = () => (
  <footer className="site-container bg-grey-900 text-white p-14">
    <div className="w-full space-y-10 lg:space-y-0 lg:flex justify-between items-center text-center ">
    <div>
      <img
        width="300"
        src="/assets/icons/cf-logo-white.svg"
        alt="Clear Factor logo"
        className="mx-auto"
      />
    </div>
    <div className="flex flex-col items-center lg:items-end space-y-5">
      <ul className="space-y-4 lg:space-y-0 lg:flex lg:space-x-12 type-base">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://3dd4b1a6.sibforms.com/serve/MUIEAOOxJgZ_KLNEAJKp8AiTcPLRGtEOVSZcJuhIHkK0xmlG2Qw2ChaftQwOJgKmZ0x2KSLglbfD0EzUgFXsn1NuXXgyFZZOxbW4iB6bawPc5S-UNQKX-sulhdjoLgbGVeLlWZ22IV6v-nB6AvUcKaoRu8ZewE7mub5e1YZqIUAg6I_8eTkmaIUJ0YbG3KXq0i6lA5XK_3BdLDgs"
            className="p-2 hover:text-green-500"
          >
            Become an introducer
          </a>
        </li>
        <li>
          <Link
            to="/frequently-asked-questions"
            className="p-2 hover:text-green-500"
          >
            FAQ's
          </Link>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lt2nxmzm.sibpages.com/"
            className="p-2 hover:text-green-500"
          >
            Request a quote
          </a>
        </li>
        <li>
          <a
            href="https://invoicefinance.clearfactor.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 hover:text-green-500"
          >
            Log in / Sign Up
          </a>
        </li>
      </ul>
      <ul className="sm:flex space-y-4 sm:space-y-0 sm:space-x-4 type-sm">
        <li>
          <Link to="/terms-and-conditions" className="p-2 hover:text-green-500">
            T&amp;C's
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" className="p-2 hover:text-green-500">
            Privacy Policy
          </Link>
        </li>
      </ul>
    </div>
    </div>
    <div className="lg:flex justify-between">
      <div className="hidden lg:block"></div>
      <div>
      <p className="type-xs text-center lg:text-right lg:max-w-2xl pt-12">              
      </p>
      <p className="pt-12 text-center lg:text-right type-base">
        Website by <a href="https://www.seenagency.co.uk/" target="_blank" rel="noopener noreferrer"  className="underline hover:text-green-500">Seen Agency</a>
      </p>
      </div>
    </div>
  </footer>
)

export default Footer
