import React from "react"
import Seo from "./seo"
import Footer from "./footer"
import Nav from "./nav"
import CookiePopUp from "./cookie-pop-up"

const Layout = ({ children, title, description, containerClass }) => {
  return (
    <>
      <Seo title={title} description={description} />
      <Nav />
      <div className="font-poppins overflow-x-hidden">
        <main className={`min-h-screen ${containerClass}`}>{children}</main>
        <Footer />
        <CookiePopUp />
      </div>
    </>
  )
}

export default Layout
