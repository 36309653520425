import React, { useState, useCallback } from "react"
import { Link } from "gatsby"

const Nav = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(e => {
    setOpen(prev => !prev)
  }, [])

  return (
    <header className="site-header site-container py-7 items-center shadow-xl">
      <div className="pr-20 lg:pr-0">
        <Link to="/">
          <img
            width="230"
            src="../assets/icons/cf-logo.svg"
            alt="Clear Factor logo"
          />
        </Link>
      </div>
      <div>
        {/* top nav - desktop */}
        <div className="hidden lg:flex items-center space-x-8 type-base text-grey-500">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://3dd4b1a6.sibforms.com/serve/MUIEAOOxJgZ_KLNEAJKp8AiTcPLRGtEOVSZcJuhIHkK0xmlG2Qw2ChaftQwOJgKmZ0x2KSLglbfD0EzUgFXsn1NuXXgyFZZOxbW4iB6bawPc5S-UNQKX-sulhdjoLgbGVeLlWZ22IV6v-nB6AvUcKaoRu8ZewE7mub5e1YZqIUAg6I_8eTkmaIUJ0YbG3KXq0i6lA5XK_3BdLDgs"
            className="p-2 hover:text-green-500"
          >
            Become an introducer
          </a>
          <Link to="/about-us" className="p-2 hover:text-green-500">About Us</Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lt2nxmzm.sibpages.com/"
            className="p-2 hover:text-green-500"
          >
            Request a quote
          </a>
          <a
            href="https://invoicefinance.clearfactor.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-green transform hover:scale-110 duration-300"
          >
            Log in / Sign Up
          </a>
        </div>
        {/* side nav - mobile */}
        <nav className={`navbar${open ? "" : " collapse"}`}>
          <div className="burger-container">
            <input
              onChange={handleOpen}
              checked={open}
              name="nav-checkbox"
              id="nav-checkbox"
              type="checkbox"
            />
            <label htmlFor="nav-checkbox">
              <div className="burger" />
            </label>
          </div>
          <div className="menu">
            <ul className={`menu-items ${open ? 'flex' : 'hidden'}`}>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://3dd4b1a6.sibforms.com/serve/MUIEAOOxJgZ_KLNEAJKp8AiTcPLRGtEOVSZcJuhIHkK0xmlG2Qw2ChaftQwOJgKmZ0x2KSLglbfD0EzUgFXsn1NuXXgyFZZOxbW4iB6bawPc5S-UNQKX-sulhdjoLgbGVeLlWZ22IV6v-nB6AvUcKaoRu8ZewE7mub5e1YZqIUAg6I_8eTkmaIUJ0YbG3KXq0i6lA5XK_3BdLDgs"
                >
                  Become an introducer
                </a>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lt2nxmzm.sibpages.com/"
                >
                  Request a quote
                </a>
              </li>
              <li>
                <a
                  href="https://invoicefinance.clearfactor.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Log in / Sign Up
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
export default Nav
